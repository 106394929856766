import { useCallback, useEffect, useState } from "react";
import { makeRequest } from "../Axios/MakeRequest";
import { PRICESCustoms } from "../Functions/PRICESCustoms";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
// import { data } from "autoprefixer";

export const Ship = () => {
  const [idsList, setIdsList] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const setIds = () => {
    const ids = localStorage.getItem("ids");
    const ids_ls = ids ? JSON.parse(ids) : [];

    setIdsList(ids_ls);
  };

  useEffect(() => {
    setIds();
  }, []);

  const fetchOrderDetails = useCallback(async () => {
    try {
      const requests = idsList.map((item) =>
        makeRequest.get(`/ex/orders/${item.osid}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        })
      );

      const responses = await Promise.allSettled(requests);

      const details = responses
        .filter((result) => result.status === "fulfilled")
        .map((result, index) => ({
          osid: idsList[index].osid,
          payid: idsList[index].payid,
          date: result.value.data.date,
          sent: result.value.data.sent,
          cart: result.value.data.cart,
        }));

      setOrderDetails(details);
    } catch (error) {
      console.error("Erro ao buscar os detalhes do pedido:", error);
    }
  }, [idsList]);

  useEffect(() => {
    if (idsList.length > 0) {
      fetchOrderDetails();
    }
  }, [idsList, fetchOrderDetails]);

  const confirmRemove = (index) => {
    Swal.fire({
      title: "Confirmar entrega",
      html: "<p>Caso seu pedido não tenha chegado, ele será removido da lista neste instante</p><p><small>Esta ação é irreversível</small></p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, chegou",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const pedidos = JSON.parse(localStorage.getItem("ids"));

        const pedidosAtualizados = pedidos.filter(
          (item) => item.osid !== index
        );

        try {
          await makeRequest.patch(
            `/update_ex_order/${index}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );
        } catch (err) {
          if (err.response) {
            console.log(err.response.data);
          }
        }

        localStorage.setItem("ids", JSON.stringify(pedidosAtualizados));

        if (pedidos.length !== 1) {
          setIds();
          fetchOrderDetails();
        } else {
          setOrderDetails(pedidosAtualizados);
        }

        Swal.fire("Removido!", "O item foi removido do carrinho.", "success");
      }
    });
  };

  return (
    <>
      <div className="m-5 border-b border-[#111111] no-spinner justify-between items-end">
        <h1 className="text-center">Pedidos</h1>
        {orderDetails.length > 0 ? (
          orderDetails.map((items, indexs) => (
            <div key={indexs}>
              <p>{items.date}</p>
              <p>
                {items.sent === true
                  ? "Enviado: Sim"
                  : items.sent === false
                  ? "Enviado: Não"
                  : "Processando pagamento"}
              </p>

              <div>
                <div className="flex justify-between items-center">
                  <p>Pedido {items.osid}</p>
                  <p>
                    <FaCheck
                      className="cursor-pointer hover:text-red-500 mr-1"
                      onClick={() => confirmRemove(items.osid)}
                    />
                  </p>
                </div>

                {items.cart.map((item, index) => (
                  <div key={index} className="mt-5">
                    <div>
                      <div>
                        {index + 1} - Tala de {item.comprimento} cm
                      </div>
                      <div className="flex justify-between items-center">
                        <div>Cor estofado </div>
                        <div
                          style={{
                            backgroundColor:
                              item.cor_estofado === ""
                                ? "#000000"
                                : item.cor_estofado,
                          }}
                          className="h-5 w-5 ml-2 border"
                        />
                      </div>
                      <div className="flex justify-between items-center">
                        <div>Cor faixa </div>
                        <div
                          style={{
                            backgroundColor:
                              item.cor_faixa === ""
                                ? "#000000"
                                : item.cor_faixa,
                          }}
                          className="h-5 w-5 ml-2 border"
                        />
                      </div>
                      <div className="flex justify-between items-center">
                        <div>Cor fita </div>
                        <div
                          style={{
                            backgroundColor:
                              item.cor_fita === "" ? "#000000" : item.cor_fita,
                          }}
                          className="h-5 w-5 ml-2 border"
                        />
                      </div>
                      <div className="flex justify-between items-center">
                        <div>Estampado</div>
                        <div
                          style={{
                            backgroundImage: `url(${
                              item.estampa === "" ? "Nenhuma" : item.estampa
                            })`,
                          }}
                          className="h-5 w-5 ml-2 border"
                        />
                      </div>
                      <div>
                        {PRICESCustoms(item.comprimento) === "121212.00" ? (
                          "Consulte com um especialista"
                        ) : (
                          <div className="flex justify-between items-center">
                            <div>R$</div>
                            <div>{PRICESCustoms(item.comprimento)}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
                  <div>Total </div>
                  <div>
                    R$
                    {items.cart.reduce(
                      (acc, item) =>
                        acc + parseFloat(PRICESCustoms(item.comprimento)),
                      0
                    )}{" "}
                    + Frete
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center mb-2">
            Nenhum detalhe de pedido encontrado
          </p>
        )}
        <p className="text-center text-xs mb-2">
          Este(s) pedido(s) fica(m) armazenado(s) em seu dispositivo, use ele
          para observar o status de andamento
        </p>
      </div>
    </>
  );
};
