import { useEffect, useState } from "react";
import { MdOutlinePending } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const Pending = () => {
  const { orderStatusId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get("payment_id");

  useEffect(() => {
    const ids = localStorage.getItem("ids");
    const ids_ls = ids ? JSON.parse(ids) : [];

    const exists = ids_ls.some(
      (item) => item.osid === orderStatusId && item.payid === paymentId
    );

    if (!exists) {
      ids_ls.push({ osid: orderStatusId, payid: paymentId });
      localStorage.setItem("ids", JSON.stringify(ids_ls));
    }
  }, [orderStatusId, paymentId]);

  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      navigate("/pedido");
    }
  }, [countdown, navigate]);

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="bg-yellow-500 rounded-full h-[50vw] w-[50vw] md:h-[30vw] md:w-[30vw] lg:h-[20vw] lg:w-[20vw] xl:h-[15vw] xl:w-[15vw] flex justify-center items-center">
        <MdOutlinePending color="#ffffff" className="h-[25%] w-[25%]" />
      </div>
      <span className="flex items-center text-center mt-10">
        O pagamento está sendo processado, se você já pagou alguarde alguns
        instantes
      </span>
      <span className="mt-5">Redirecionando em {countdown}...</span>
    </div>
  );
};
