import { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { PRICESCustoms } from "../Functions/PRICESCustoms";
import Swal from "sweetalert2";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../Axios/MakeRequest";

export const Cart = () => {
  const [visible, setVisible] = useState(false);

  const [carrinho, setCarrinho] = useState(
    JSON.parse(localStorage.getItem("carrinho")) || []
  );

  useEffect(() => {
    localStorage.setItem("carrinho", JSON.stringify(carrinho));
  }, [carrinho]);

  const removeItem = (index) => {
    const updatedCarrinho = carrinho.filter((_, i) => i !== index);
    setCarrinho(updatedCarrinho);
  };

  const confirmRemove = (index) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Você realmente deseja remover este item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sim, remover",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeItem(index);
        Swal.fire("Removido!", "O item foi removido do carrinho.", "success");
      }
    });
  };

  const prices = carrinho.map((item) => PRICESCustoms(item.comprimento));
  const total = prices.reduce((acc, val) => acc + parseFloat(val), 0);

  const navigate = useNavigate();

  const [frete, setFrete] = useState([]);
  const [fretePreco, setFretePreco] = useState(0);
  const [cepFrete, setCepFrete] = useState("");
  const [statusFrete, setStatusFrete] = useState("");

  const isFrete = () => {
    Swal.fire({
      title: "Frete vazio",
      text: "Antes de finalizar o pedido, selecione um frete",
      icon: "warning",
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Vou atualizar meu frete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      }
    });
  };

  const calculate_frete = () => {
    Swal.fire({
      title: "Calcular frete",
      icon: "warning",
      input: "text",
      inputAttributes: {
        placeholder: "Digite seu CEP",
        "aria-label": "Digite seu CEP",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Você precisa inserir um CEP!";
        }
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#e5e5e5",
      confirmButtonText: "Enviar",
      cancelButtonText: "Voltar",
      customClass: {
        input:
          "border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500",
        cancelButton: "custom-confirm-btn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStatusFrete("loading");
        const cep = result.value;
        try {
          const response = await makeRequest.post(
            "calcula/frete",
            {
              address: {
                cep_formatado: cep,
              },
              servicos: ["E", "X"],
              tala: carrinho,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );

          const res_data = response.data;
          console.log(res_data);
          setFrete(res_data);
          setCepFrete(cep);

          const result = Array.isArray(res_data)
            ? res_data.find((item) => item.servico === "X")?.vlrFrete || 0
            : 0;
          setFretePreco(result);
          setStatusFrete("pass");
          localStorage.setItem(
            "frete",
            JSON.stringify([{ servico: "X", cep: cep }])
          );
        } catch (error) {
          console.error("Erro na requisição:", error);
          setStatusFrete("error");
        }

        Swal.close();
      }
    });
  };

  const handleFinishOrder = () => {
    if (fretePreco !== 0) {
      setVisible(true);

      setTimeout(() => {
        navigate("/checkout");
      }, 1000);
    } else {
      isFrete();
    }
  };

  const handleChange = (e) => {
    const frete = JSON.parse(e.target.value);
    setFretePreco(frete.frete);

    localStorage.setItem(
      "frete",
      JSON.stringify([{ servico: frete.servico, cep: frete.cep }])
    );
  };

  return (
    <>
      {visible ? (
        <div className={`absolute z-20 bg-white w-full h-full fade-in`} />
      ) : (
        <></>
      )}
      {carrinho.length !== 0 ? (
        <>
          {carrinho.map((item, index) => {
            return (
              <div
                key={index}
                className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end"
              >
                <div>
                  <div>
                    {index + 1} - Tala de {item.comprimento} cm
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor estofado </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_estofado === ""
                            ? "#000000"
                            : item.cor_estofado,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor faixa </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_faixa === "" ? "#000000" : item.cor_faixa,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Cor fita </div>
                    <div
                      style={{
                        backgroundColor:
                          item.cor_fita === "" ? "#000000" : item.cor_fita,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div>Estampado</div>
                    <div
                      style={{
                        backgroundImage: `url(${
                          item.estampa === "" ? "Nenhuma" : item.estampa
                        })`,
                      }}
                      className="h-5 w-5 ml-2 border"
                    />
                  </div>
                  <div>
                    {PRICESCustoms(item.comprimento) === "121212.00" ? (
                      "Consulte com um especialista"
                    ) : (
                      <div className="flex justify-between items-center">
                        <div>R$</div>
                        <div>{PRICESCustoms(item.comprimento)}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-1">
                  <FaTrashAlt
                    size={20}
                    onClick={() => confirmRemove(index)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            );
          })}

          <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
            <div
              onClick={() => calculate_frete()}
              className="hover:cursor-pointer"
            >
              Calcular Frete
            </div>
            <div>
              <>
                {statusFrete === "loading" ? (
                  <div className="loader" />
                ) : statusFrete === "error" ? (
                  "Ops, houve um erro. Volte mais tarde"
                ) : statusFrete === "pass" ? (
                  <>
                    <select
                      className="outline-none webkit-none"
                      onChange={(e) => handleChange(e)}
                    >
                      {Array.isArray(frete) ? (
                        frete.map((item, index) => (
                          <option
                            value={JSON.stringify({
                              servico: item.servico,
                              frete: item.vlrFrete,
                              cep: cepFrete,
                            })}
                            key={index}
                          >
                            {item.servico === "X"
                              ? `Sedex ${item.prazoEntMin}/${item.prazoEnt} dias`
                              : `(PAC) ${item.prazoEntMin}/${item.prazoEnt} dias`}{" "}
                            - R$ {item.vlrFrete.toFixed(2)}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          Nenhum frete disponível
                        </option>
                      )}
                    </select>
                  </>
                ) : (
                  <div
                    onClick={() => calculate_frete()}
                    className="cursor-pointer"
                  >
                    --:--
                  </div>
                )}
              </>
            </div>
          </div>
          <div
            className="m-5 no-spinner flex justify-center hover:cursor-pointer"
            onClick={() => navigate("/checkout")}
          ></div>

          <div className="m-5 border-b border-[#111111] no-spinner flex justify-between items-end">
            <div>Total </div>
            <div>R$ {(total + fretePreco).toFixed(2)}</div>
          </div>
          <div
            className="m-5 no-spinner flex justify-center hover:cursor-pointer"
            onClick={() => handleFinishOrder()}
          >
            Finalizar pedido
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <MdOutlineRemoveShoppingCart size={98} color="#111111" />
          <div className="mt-4">Seu carrinho está vazio</div>
        </div>
      )}
    </>
  );
};
