import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Swal from "sweetalert2";

export const CustomPrices = () => {
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();
  const preco = useSelector((state) => state.price.preco);
  const isPreco121212 = preco === "121212.00";

  const confirmFeito = () => {
    Swal.fire({
      title: "Alerta!",
      text: "Você deve colocar um tamanho para a tala",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e5e5e5",
      customClass: {
        confirmButton: "custom-confirm-btn",
      },
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Ver meu carrinho",
      cancelButtonText: "Voltar",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/carrinho");
      }
    });
  };

  const dispatch = useDispatch();

  const talaCarrinho = useSelector((state) => state.item);

  const HandleClick = (caminho, ignore) => {
    if (preco !== null) {
      setVisible(true);

      const carrinho = localStorage.getItem("carrinho");
      const carrinhoArray = carrinho ? JSON.parse(carrinho) : [];

      if (talaCarrinho) {
        carrinhoArray.push(...talaCarrinho);
      }

      localStorage.setItem("carrinho", JSON.stringify(carrinhoArray));

      const payload = {
        comprimento: "",
        cor_faixa: "",
        cor_fita: "",
        cor_estofado: "",
        estampa: "",
      };

      dispatch({ type: "SET_PRECO", payload: null });
      dispatch({ type: "SET_ITEM", payload: payload });

      setTimeout(() => {
        navigate(caminho);
      }, 1000);
    } else {
      if (ignore) {
        setVisible(true);
        setTimeout(() => {
          navigate(caminho);
        }, 1000);
      } else {
        confirmFeito();
      }
    }
  };

  return (
    <>
      {visible ? (
        <div className={`absolute z-20 bg-white w-full h-full fade-in`} />
      ) : (
        <></>
      )}
      <div className="absolute top-0 left-0 right-0 flex justify-between items-center text-sm p-10 bg-transparent z-10">
        <div>
          <div>Tala extensora customizável</div>
          <div>
            $
            {preco !== null
              ? isPreco121212
                ? " Consultar com especialista"
                : preco
              : " --:--"}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="rounded-full bg-gray-200 flex items-center justify-center w-11 h-11"
            onClick={() => HandleClick("/pedido", true)}
          >
            ↑
          </button>
          <button
            className="rounded-full border px-6 py-3 border-gray-300 bg-gray-50"
            onClick={() => HandleClick("/carrinho", false)}
          >
            Feito
          </button>
        </div>
      </div>
    </>
  );
};
