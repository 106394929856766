export const PRICESCustoms = (comprimento) => {
  let preco;

  if (comprimento <= 20) {
    preco = 280.0;
  } else if (comprimento >= 21 && comprimento <= 25) {
    preco = 300.0;
  } else if (comprimento >= 26 && comprimento <= 30) {
    preco = 350.0;
  } else if (comprimento >= 31 && comprimento <= 35) {
    preco = 370.0;
  } else if (comprimento >= 36 && comprimento <= 40) {
    preco = 390.0;
  } else if (comprimento >= 41 && comprimento <= 45) {
    preco = 420.0;
  } else if (comprimento >= 46 && comprimento <= 49) {
    preco = 480.0;
  } else if (comprimento >= 50 && comprimento <= 53) {
    preco = 500.0;
  } else if (comprimento >= 54 && comprimento <= 57) {
    preco = 550.0;
  } else if (comprimento >= 58 && comprimento <= 60) {
    preco = 600.0;
  } else if (comprimento >= 61 && comprimento <= 65) {
    preco = 650.0;
  } else if (comprimento >= 66 && comprimento <= 70) {
    preco = 700.0;
  } else if (comprimento >= 71 && comprimento <= 80) {
    preco = 750.0;
  } else {
    preco = 121212.0;
  }

  return preco.toFixed(2);
};
