import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { OBJViewer } from "../Functions/OBJViewer";
import { useNavigate } from "react-router-dom";
import { GetTalaOptions } from "../Data/Data";
import "slick-carousel/slick/slick-theme.css";
import { CustomInput } from "./CustomInput";
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";

export const FitasCustomColor = () => {
  const dispatch = useDispatch();

  const color = useSelector((state) => state.item[0].cor_fita);

  const setColor = (cor) => {
    dispatch({ type: "SET_ITEM", payload: { cor_fita: cor } });
  };

  const data = GetTalaOptions("fitas");

  return (
    <>
      <div className="overflow-hidden">
        <OBJViewer
          objPath={["../Models/fitas.glb"]}
          color={color}
          isPatterned={false}
        />
        <div className="absolute bottom-0 mb-16 w-[100%] text-center">
          Fitas (velcro) da tala
        </div>

        {Array.isArray(data) && data.length > 0 && (
          <CustomInput
            setState={setColor}
            data={data
              .map((item) => item.cores_fitas.map((fita) => fita.color))
              .flat()}
            slidesNumber={2}
            description={"Fitas (Fixação da tala na perna)"}
          />
        )}
      </div>
    </>
  );
};

export const Customs1 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      localStorage.removeItem("hasReloaded");
    }
  }, [navigate]);

  const nextSlide = () => {
    navigate("/2");
  };

  const prevSlide = () => {
    navigate("/");
  };

  return (
    <>
      <div className="fade-in">
        <FitasCustomColor />
        <div className=" absolute bottom-0 p-0" onClick={prevSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronLeft />
            </button>
          </div>
        </div>

        <div className="absolute bottom-0 p-0 right-0" onClick={nextSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
