import { Cart } from "../../components/Cart/Cart";
import { Navbar } from "../../components/Navbar/Navbar";

export const Carrinho = () => {
  return (
    <>
      <div className="fade-in">
        <Navbar />
        <Cart />
      </div>
    </>
  );
};
