const initialState = [
  {
    comprimento: "",
    cor_faixa: "",
    cor_fita: "",
    cor_estofado: "",
    estampa: "",
  },
];

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ITEM":
      return [
        {
          ...state[0],
          ...action.payload,
        },
      ];
    default:
      return state;
  }
};

export default itemReducer;
