import { CustomPrices } from "../../components/Customs/CustomPrices";
import { Customs } from "../../components/Customs/Customs";
import { Customs1 } from "../../components/Customs/Customs1";
import { Customs2 } from "../../components/Customs/Customs2";
import { Customs3 } from "../../components/Customs/Customs3";
import { Customs4 } from "../../components/Customs/Customs4";

export const Home = () => {
  return (
    <>
      <CustomPrices />
      <Customs />
    </>
  );
};

export const Home1 = () => {
  return (
    <>
      <CustomPrices />
      <Customs1 />
    </>
  );
};

export const Home2 = () => {
  return (
    <>
      <CustomPrices />
      <Customs2 />
    </>
  );
};

export const Home3 = () => {
  return (
    <>
      <CustomPrices />
      <Customs3 />
    </>
  );
};

export const Home4 = () => {
  return (
    <>
      <CustomPrices />
      <Customs4 />
    </>
  );
};
