import { Navbar } from "../../components/Navbar/Navbar";
import { Ship } from "../../components/Ship/Ship";

export const Pedido = () => {
  return (
    <div className="fade-in">
      <Navbar />
      <Ship />
    </div>
  );
};
