import Slider from "react-slick";

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-prev custom-arrow"
      onClick={onClick}
      style={{
        borderRadius: "50%",
        border: "none",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        fontSize: "22px",
        cursor: "pointer",
        position: "absolute",
        left: "-30px",
        content: "",
      }}
    >
      ←
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="slick-next custom-arrow"
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        borderRadius: "50%",
        border: "none",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#000",
        fontSize: "22px",
        cursor: "pointer",
        position: "absolute",
        right: "-30px",
        content: "",
      }}
    >
      →
    </button>
  );
};

export const CustomInput = ({ setState, data, slidesNumber, description }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesNumber,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="mt-[-80px] text-center">{description}</div>
      {/* <div className="flex justify-center mt-[10px] relative"> */}
      <div className="flex justify-center absolute bottom-0 w-full mb-4">
        <div style={{ maxWidth: "30%" }}>
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index} className="flex-important">
                <button
                  className="w-8 h-8 rounded-full border border-black"
                  style={
                    typeof item === "string" && item.startsWith("http")
                      ? { backgroundImage: `url(${item})` }
                      : { backgroundColor: item }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setState(item);
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};
