import React from "react";
import { PRICESCustoms } from "../Functions/PRICESCustoms";
import { useDispatch, useSelector } from "react-redux";

export const CustomInputPrices = () => {
  const comprimento = useSelector((state) => state.item[0].comprimento);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const value = event.target.value;

    dispatch({ type: "SET_ITEM", payload: { comprimento: value } });

    if (value) {
      const price = PRICESCustoms(Number(value));
      dispatch({ type: "SET_PRECO", payload: price });
    }
  };

  return (
    <div className="flex justify-center absolute bottom-0 w-full mb-3">
      <select
        value={comprimento}
        onChange={handleChange}
        className="custom border-b select-center border-black text-black p-1 w-full text-center focus:outline-none focus:ring-0 transition duration-200 no-spinner mb-5 m-[65px] bg-transparent"
      >
        <option value="" disabled>
          Selecione o comprimento (cm)
        </option>
        {Array.from({ length: 100 }, (_, i) => i + 1).map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
    </div>
  );
};
