import { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export const Failed = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      navigate("/");
    }
  }, [countdown, navigate]);

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="bg-red-500 rounded-full h-[50vw] w-[50vw] md:h-[30vw] md:w-[30vw] lg:h-[20vw] lg:w-[20vw] xl:h-[15vw] xl:w-[15vw] flex justify-center items-center">
        <FaXmark color="#ffffff" className="h-[25%] w-[25%]" />
      </div>
      <span className="flex items-center text-center mt-10">
        O pagamento falhou, você será redirecionado em alguns instantes
      </span>
      <span className="mt-5">Redirecionando em {countdown}...</span>
    </div>
  );
};
