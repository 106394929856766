import { makeRequest } from "../Axios/MakeRequest";
import { useQuery } from "@tanstack/react-query";

export const Data = [
  {
    cores_faixas: [
      {
        color: "#000000",
        description: "black",
      },
      {
        color: "#ffffff",
        description: "white",
      },
      {
        color: "#db7dcf",
        description: "pink",
      },
    ],
    cores_fitas: [
      {
        color: "#000000",
        description: "black",
      },
      {
        color: "#ffffff",
        description: "white",
      },
    ],
    cores_estofado: [
      {
        color: "#000000",
        description: "black",
      },
      {
        color: "#ffffff",
        description: "white",
      },
      {
        color: "#ff7300",
        description: "orange",
      },
    ],
    estampas: [
      {
        estampa: "https://i.postimg.cc/QVGbn0T2/textura.png",
        description: "texture",
      },
      {
        estampa: "https://i.postimg.cc/CxThwFKV/fofo.png",
        description: "fofo",
      },
      {
        estampa: "https://i.postimg.cc/MpShHXJq/roxo.png",
        description: "roxo",
      },
      {
        estampa: "https://i.postimg.cc/Bn7Kt5T9/rosa.jpg",
        description: "texture",
      },
      {
        estampa: "https://i.postimg.cc/QVGbn0T2/textura.png",
        description: "texture",
      },
      {
        estampa: "https://i.postimg.cc/pdtpm0mS/batman.jpg",
        description: "batman",
      },
    ],
  },
];

export const GetTalaOptions = (key) => {
  const { isLoading, error, data } = useQuery({
    queryKey: [key],
    queryFn: () =>
      makeRequest
        .get("/tala_options", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        })
        .then((res) => res.data),
  });

  const fitaColors = Array.isArray(data) ? data : [];

  return isLoading ? "isloading..." : error ? "error" : fitaColors;
};
