import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center h-16 bg-[#111111] text-white p-5">
        <div onClick={() => navigate("/")}>
          <IoIosArrowRoundBack size={32} />
        </div>
        <div>
          <img src="./logo192.png" alt="logo" className="h-12 w-auto" />
        </div>
      </div>
    </>
  );
};
