import React, { useEffect, useState } from "react";
import { makeRequest } from "../Axios/MakeRequest";
import { MdShoppingCartCheckout } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";
import { loadMercadoPago } from "@mercadopago/sdk-js";

export const CheckoutBrik = () => {
  const navigate = useNavigate();
  const freteData = JSON.parse(localStorage.getItem("frete"));
  const carrinhoData = JSON.parse(localStorage.getItem("carrinho"));

  const [formData, setFormData] = useState({
    streetName: "",
    streetNumber: "",
    zipCode: freteData && freteData.length > 0 ? freteData[0].cep : "",
    email: "",
    idNumber: "",
    idType: "CPF",
    name: "",
    phoneAreaCode: "",
    phoneNumber: "",
    surname: "",
    tala: Array.isArray(carrinhoData) ? carrinhoData : [],
    frete: freteData && freteData.length > 0 ? freteData[0] : null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "idNumber") {
      const onlyNumbers = value.replace(/\D/g, "");

      if (onlyNumbers.length > 14) {
        return;
      }

      let formattedValue = "";

      if (onlyNumbers.length <= 11) {
        formattedValue = onlyNumbers
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{2})$/, "$1-$2");
      } else {
        formattedValue = onlyNumbers
          .replace(/(\d{2})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d{2})$/, "$1-$2");
      }

      setFormData((prevData) => ({
        ...prevData,
        idNumber: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    try {
      const response = await makeRequest.post(
        "/checkout",
        {
          address: {
            street_name: formData.streetName,
            street_number: formData.streetNumber,
            zip_code: formData.zipCode,
          },
          email: formData.email,
          idnumber: formData.idNumber,
          idtype: formData.idType,
          name: formData.name,
          phone: {
            area_code: parseInt(formData.phoneAreaCode),
            number: formData.phoneNumber,
          },
          surname: formData.surname,
          tala: formData.tala,
          frete: {
            servico: formData.frete.servico,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        }
      );

      // window.location.href = response.data.checkout_url;
      localStorage.removeItem("frete");

      await loadMercadoPago();
      const mp = new window.MercadoPago(process.env.REACT_APP_PUBLIC_KEY, {
        locale: "pt-BR",
      });

      try {
        mp.checkout({
          preference: {
            id: response.data.preference_id,
          },
          autoOpen: true,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <>
      {visible ? (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 fade-in">
          <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between">
              <h2 className="text-xl font-bold mb-4">Pre Checkout</h2>
              <div className="relative">
                <div className="absolute bg-green-500 w-3 h-3 rounded-2xl right-0 pulse" />
                <div className="absolute bg-green-500 w-3 h-3 rounded-2xl right-0" />
                <MdShoppingCartCheckout
                  size={40}
                  onClick={() => navigate("/carrinho")}
                  className="hover:cursor-pointer"
                />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex gap-5">
                <div className="mb-4 w-1/3">
                  <label className="block text-gray-700" htmlFor="name">
                    Nome:
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                    placeholder="João"
                    required
                  />
                </div>
                <div className="mb-4 w-2/3">
                  <label className="block text-gray-700" htmlFor="surname">
                    Sobrenome:
                  </label>
                  <input
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                    placeholder="Silva"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded"
                  placeholder="joao.silva@dominio.com"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="idType">
                  Documento:
                </label>
                <select
                  name="idType"
                  value={formData.idType}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded"
                >
                  <option value="CPF">CPF</option>
                  <option value="CNPJ">CNPJ</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="idNumber">
                  Nº do documento:
                </label>
                <input
                  type="text"
                  name="idNumber"
                  value={formData.idNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded"
                  placeholder="000.000.000-00"
                  required
                />
              </div>
              <div className="flex gap-5">
                <div className="mb-4 w-2/3">
                  <label className="block text-gray-700" htmlFor="streetName">
                    Rua:
                  </label>
                  <input
                    type="text"
                    name="streetName"
                    value={formData.streetName}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                    placeholder="R. Dr João"
                    required
                  />
                </div>
                <div className="mb-4 w-1/3">
                  <label className="block text-gray-700" htmlFor="streetNumber">
                    Número:
                  </label>
                  <input
                    type="text"
                    name="streetNumber"
                    value={formData.streetNumber}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                    placeholder="00"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700" htmlFor="zipCode">
                  <div className="flex justify-between items-end">
                    <span>CEP:</span>
                    <Link to="/carrinho" className="text-xs">
                      CEP errado? Clique aqui
                    </Link>
                  </div>
                </label>
                <input
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded"
                  placeholder="00000000"
                  readOnly
                />
              </div>
              <div className="flex gap-5">
                <div className="mb-4 w-1/3">
                  <label
                    className="block text-gray-700"
                    htmlFor="phoneAreaCode"
                  >
                    Área:
                  </label>
                  <input
                    type="number"
                    name="phoneAreaCode"
                    value={formData.phoneAreaCode}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded no-counter"
                    placeholder="+(11)"
                    min="1"
                    required
                  />
                </div>
                <div className="mb-4 w-2/3">
                  <label className="block text-gray-700" htmlFor="phoneNumber">
                    Telefone:
                  </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded"
                    required
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
