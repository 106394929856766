import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CustomInputPrices } from "./CustomInputPrices";
import { OBJViewer } from "../Functions/OBJViewer";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

export const TalaViewer = () => {
  return (
    <>
      <div className="overflow-hidden">
        <OBJViewer objPath={["../Models/tala.glb"]} isPatterned={false} />
        <CustomInputPrices />
      </div>
    </>
  );
};

export const Customs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      localStorage.removeItem("hasReloaded");
    }
  }, [navigate]);

  const nextSlide = () => {
    navigate("/1");
  };

  const prevSlide = () => {
    navigate("/4");
  };

  return (
    <>
      <div className="fade-in">
        <TalaViewer />
        <div className=" absolute bottom-0 p-0" onClick={prevSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronLeft />
            </button>
          </div>
        </div>

        <div className="absolute bottom-0 p-0 right-0" onClick={nextSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
