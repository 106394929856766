const initialState = {
  preco: null,
};

const priceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRECO":
      return { ...state, preco: action.payload };
    default:
      return state;
  }
};

export default priceReducer;
