// import React, { useEffect, useRef } from "react";
// import * as THREE from "three";
// import { OBJLoader } from "three-stdlib";
// import { OrbitControls } from "three-stdlib";
// import { gsap } from "gsap";

// export const OBJViewer = ({ objPath, color, isPatterned, estampa }) => {
//   const mountRef = useRef(null);
//   const cameraRef = useRef(null);
//   const isDragging = useRef(false);
//   const mouseDownTime = useRef(0);

//   useEffect(() => {
//     const mountNode = mountRef.current;

//     const scene = new THREE.Scene();
//     const camera = new THREE.PerspectiveCamera(
//       75,
//       window.innerWidth / window.innerHeight,
//       0.1,
//       1000
//     );
//     camera.position.set(2.5, 1.5, 3.5);

//     cameraRef.current = camera;
//     const renderer = new THREE.WebGLRenderer({ antialias: true });
//     renderer.setSize(window.innerWidth, window.innerHeight);
//     renderer.setPixelRatio(window.devicePixelRatio);
//     renderer.setClearColor(0xffffff, 1);
//     renderer.shadowMap.enabled = true;
//     renderer.shadowMap.type = THREE.PCFSoftShadowMap;
//     renderer.toneMapping = THREE.ACESFilmicToneMapping; // Ou outro método de tonificação
//     renderer.toneMappingExposure = 1; // Aumente ou diminua este valor

//     mountNode.appendChild(renderer.domElement);

//     const handleResize = () => {
//       const { innerWidth, innerHeight } = window;
//       camera.aspect = innerWidth / innerHeight;
//       camera.updateProjectionMatrix();
//       renderer.setSize(innerWidth, innerHeight);
//     };

//     window.addEventListener("resize", handleResize);

//     const light = new THREE.DirectionalLight(0xffffff, 0.5);
//     light.position.set(0, 5, 5).normalize();
//     light.castShadow = true;
//     scene.add(light);

//     const ambientLight = new THREE.AmbientLight(0xffffff, 1);
//     scene.add(ambientLight);

//     const planeGeometry = new THREE.PlaneGeometry(200, 200);
//     const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });
//     const plane = new THREE.Mesh(planeGeometry, planeMaterial);
//     plane.rotation.x = -Math.PI / 2;
//     plane.position.y = -1;
//     plane.receiveShadow = true;
//     scene.add(plane);

//     const loader = new OBJLoader();
//     loader.load(
//       objPath[0],
//       (object) => {
//         object.traverse((child) => {
//           if (child.isMesh) {
//             child.castShadow = true;
//             child.receiveShadow = false;
//             child.geometry.computeVertexNormals();

//             const setColor = (color) => {
//               if (color === "#000000" || color === "#ffffff") {
//                 const pColor = new THREE.Color("#000000");
//                 const sColor = new THREE.Color("#ffffff");
//                 const tColor =
//                   color === "#000000" ? 1 : color === "#ffffff" ? 5 : 1;
//                 const nColor = pColor.clone().lerp(sColor, tColor);
//                 return nColor;
//               } else {
//                 const nColor = new THREE.Color(color);
//                 return nColor;
//               }
//             };

//             const est =
//               estampa === ""
//                 ? "https://i.postimg.cc/QVGbn0T2/textura.png"
//                 : estampa;

//             if (isPatterned) {
//               child.material = new THREE.MeshStandardMaterial({
//                 color: setColor(color),
//                 roughness: 1.1,
//                 metalness: 0,
//                 map: new THREE.TextureLoader().load(est),
//                 emissive: new THREE.Color(0, 0, 0),
//                 opacity: 1,
//                 transparent: true,
//               });
//               loader.setMaterials(child.material);
//             } else {
//               if (!child.material.emissive) {
//                 child.material = new THREE.MeshStandardMaterial({
//                   color: setColor(color),
//                 });
//               } else {
//                 child.material.color = setColor(color);
//               }
//             }
//           }
//         });

//         object.rotation.x = Math.PI / -10;

//         scene.add(object);
//       },
//       (xhr) => {
//         console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
//       },
//       (error) => {
//         console.error("An error happened", error);
//       }
//     );
//     // });

//     const controls = new OrbitControls(camera, renderer.domElement);

//     const handleMouseDown = () => {
//       isDragging.current = true;
//       mouseDownTime.current = performance.now();
//     };

//     const handleMouseUp = (event) => {
//       isDragging.current = false;
//       const elapsedTime = performance.now() - mouseDownTime.current;

//       if (elapsedTime < 200) {
//         handleClick(event);
//       }
//     };

//     const handleClick = (event) => {
//       const mouse = new THREE.Vector2(
//         (event.clientX / window.innerWidth) * 2 - 1,
//         -(event.clientY / window.innerHeight) * 2 + 1
//       );

//       const raycaster = new THREE.Raycaster();
//       raycaster.setFromCamera(mouse, camera);

//       const intersects = raycaster.intersectObjects(scene.children, true);
//       if (intersects.length > 0) {
//         const target = intersects[0].point;
//         const duration = 1;

//         gsap.to(camera.position, {
//           x: target.x,
//           y: target.y + 1,
//           z: target.z + 3,
//           duration: duration,
//           onUpdate: () => {
//             camera.lookAt(target);
//           },
//         });
//       }
//     };

//     mountNode.addEventListener("mousedown", handleMouseDown);
//     mountNode.addEventListener("mouseup", handleMouseUp);

//     const animate = () => {
//       requestAnimationFrame(animate);
//       controls.update();
//       renderer.render(scene, camera);
//     };
//     animate();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       mountNode.removeEventListener("mousedown", handleMouseDown);
//       mountNode.removeEventListener("mouseup", handleMouseUp);
//       mountNode.removeChild(renderer.domElement);
//     };
//   }, [objPath, color, isPatterned, estampa]);

//   return <div ref={mountRef} className="h-[100vh]" />;
// };

import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from "react";
import * as THREE from "three";
import { GLTFLoader } from "three-stdlib";
import { OrbitControls } from "three-stdlib";
import { gsap } from "gsap";

export const OBJViewer = forwardRef(
  ({ objPath, color, isPatterned, estampa }, ref) => {
    const mountRef = useRef(null);
    const cameraRef = useRef(null);
    const isDragging = useRef(false);
    const mouseDownTime = useRef(0);

    const sceneRef = useRef(new THREE.Scene()); // Armazena a cena
    const objectRef = useRef(null); // Armazena o objeto carregado
    const [isLoading, setIsLoading] = useState(true); // Estado para controle de carregamento

    useImperativeHandle(ref, () => ({
      destroy: () => {
        if (objectRef.current) {
          // Remove o objeto da cena
          sceneRef.current.remove(objectRef.current);

          // Destrói a geometria e os materiais
          objectRef.current.traverse((child) => {
            if (child.isMesh) {
              child.geometry.dispose();
              if (child.material) {
                if (Array.isArray(child.material)) {
                  child.material.forEach((mat) => mat.dispose());
                } else {
                  child.material.dispose();
                }
              }
            }
          });

          objectRef.current = null; // Limpa a referência do objeto
        }
      },
    }));

    useEffect(() => {
      const mountNode = mountRef.current;

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      camera.position.set(2.5, 1.5, 3.5);
      cameraRef.current = camera;

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setClearColor(0xffffff, 1);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      mountNode.appendChild(renderer.domElement);

      const handleResize = () => {
        const { innerWidth, innerHeight } = window;
        camera.aspect = innerWidth / innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(innerWidth, innerHeight);
      };

      window.addEventListener("resize", handleResize);

      const light = new THREE.DirectionalLight(
        0xffffff,
        isPatterned ? 0.5 : 10
      );
      light.position.set(0, 5, 5).normalize();
      light.castShadow = true;
      scene.add(light);

      const fillLight = new THREE.DirectionalLight(
        0xffffff,
        isPatterned ? 0.5 : 5
      );
      fillLight.position.set(-5, 5, -5);
      scene.add(fillLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      scene.add(ambientLight);

      const planeGeometry = new THREE.PlaneGeometry(200, 200);
      const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.rotation.x = -Math.PI / 2;
      plane.position.y = -1;
      plane.receiveShadow = true;
      scene.add(plane);

      const loader = new GLTFLoader();
      loader.load(
        objPath[0],
        (gltf) => {
          const object = gltf.scene;
          object.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = false;
              child.geometry.computeVertexNormals();

              const setColor = (color) => {
                if (color === "#000000" || color === "#ffffff") {
                  const pColor = new THREE.Color("#000000");
                  const sColor = new THREE.Color("#ffffff");
                  const tColor =
                    color === "#000000" ? 1 : color === "#ffffff" ? 5 : 1;
                  const nColor = pColor.clone().lerp(sColor, tColor);
                  return nColor;
                } else {
                  const nColor = new THREE.Color(color);
                  return nColor;
                }
              };

              const est =
                estampa === "" ? "./images/estofado-preto.jpg" : estampa;

              if (isPatterned) {
                child.material = new THREE.MeshStandardMaterial({
                  color: setColor(color),
                  roughness: 0.8,
                  metalness: 0,
                  map: new THREE.TextureLoader().load(est),
                  emissive: new THREE.Color(0, 0, 0),
                  opacity: 1,
                  transparent: true,
                });
              } else {
                child.material.color.set(setColor(color));
              }
            }
          });

          object.rotation.x = Math.PI / -10;

          setTimeout(() => {
            setIsLoading(false); // Atualiza o estado para indicar que o carregamento foi concluído
            scene.add(object);
            objectRef.current = object; // Atualiza a referência do objeto carregado
          }, 1000);
        },
        (xhr) => {
          if (xhr.total > 0) {
            // console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
          } else {
            // console.log(`Loaded: ${xhr.loaded}`);
          }
        },
        (error) => {
          console.error("An error happened", error);
          setIsLoading(false); // Atualiza o estado em caso de erro no carregamento
        }
      );

      const controls = new OrbitControls(camera, renderer.domElement);

      const handleMouseDown = () => {
        isDragging.current = true;
        mouseDownTime.current = performance.now();
      };

      const handleMouseUp = (event) => {
        isDragging.current = false;
        const elapsedTime = performance.now() - mouseDownTime.current;

        if (elapsedTime < 200) {
          handleClick(event);
        }
      };

      const handleClick = (event) => {
        const mouse = new THREE.Vector2(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(scene.children, true);
        if (intersects.length > 0) {
          const target = intersects[0].point;
          const duration = 1;

          gsap.to(camera.position, {
            x: target.x,
            y: target.y + 1,
            z: target.z + 3,
            duration: duration,
            onUpdate: () => {
              camera.lookAt(target);
            },
          });
        }
      };

      mountNode.addEventListener("mousedown", handleMouseDown);
      mountNode.addEventListener("mouseup", handleMouseUp);

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      return () => {
        window.removeEventListener("resize", handleResize);
        mountNode.removeEventListener("mousedown", handleMouseDown);
        mountNode.removeEventListener("mouseup", handleMouseUp);
        mountNode.removeChild(renderer.domElement);
      };
    }, [objPath, color, isPatterned, estampa]);

    return (
      <div ref={mountRef} className="h-[100vh] relative">
        {isLoading && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center text-center">
            <div className="mb-2">
              Caso não veja algo depois do carregamento, aguarde alguns segundos
            </div>
            <div className="loader" />
          </div>
        )}
      </div>
    );
  }
);
