import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { OBJViewer } from "../Functions/OBJViewer";
import { useNavigate } from "react-router-dom";
import { GetTalaOptions } from "../Data/Data";
import "slick-carousel/slick/slick-theme.css";
import { CustomInput } from "./CustomInput";
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";

export const TalaCustomFaixas = () => {
  const dispatch = useDispatch();

  const color = useSelector((state) => state.item[0].cor_faixa);

  const setColor = (cor) => {
    dispatch({ type: "SET_ITEM", payload: { cor_faixa: cor } });
  };

  const data = GetTalaOptions("faixa");

  return (
    <>
      <div className="overflow-hidden">
        <OBJViewer
          objPath={["../Models/faixas.glb"]}
          estampa={
            color === "#000000" || color === ""
              ? "./images/malha-preta.png"
              : color === "#ffffff"
              ? "./images/malha-preta.png"
              : "./images/malha-branca.png"
          }
          color={color}
          isPatterned={true}
        />
        <div className="absolute bottom-0 mb-16 w-[100%] text-center">
          Faixas (de apoio) da tala
        </div>
        {Array.isArray(data) && data.length > 0 && (
          <CustomInput
            setState={setColor}
            data={data
              .map((item) => item.cores_faixas.map((faixa) => faixa.color))
              .flat()}
            slidesNumber={3}
            description={"Faixas (Entre estofado e fitas)"}
          />
        )}
      </div>
    </>
  );
};

export const Customs4 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      localStorage.removeItem("hasReloaded");
    }
  }, [navigate]);

  const nextSlide = () => {
    navigate("/");
  };

  const prevSlide = () => {
    navigate("/3");
  };

  return (
    <>
      <div className="fade-in">
        <TalaCustomFaixas />
        <div className=" absolute bottom-0 p-0" onClick={prevSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronLeft />
            </button>
          </div>
        </div>

        <div className="absolute bottom-0 p-0 right-0" onClick={nextSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
