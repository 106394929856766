import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { OBJViewer } from "../Functions/OBJViewer";
import { useNavigate } from "react-router-dom";
import { GetTalaOptions } from "../Data/Data";
import "slick-carousel/slick/slick-theme.css";
import { CustomInput } from "./CustomInput";
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";

export const TalaCustomPatterned = () => {
  const dispatch = useDispatch();

  const estampa = useSelector((state) => state.item[0].estampa);

  const setEstampa = (estampa) => {
    dispatch({ type: "SET_ITEM", payload: { estampa: estampa } });
  };

  const data = GetTalaOptions("estampa");

  return (
    <>
      <div className="overflow-hidden">
        <OBJViewer
          objPath={["../Models/estampas.glb"]}
          estampa={estampa}
          isPatterned={true}
        />
        <div className="absolute bottom-0 mb-16 w-[100%] text-center">
          Estampa (externa) da tala
        </div>
        {Array.isArray(data) && data.length > 0 && (
          <CustomInput
            setState={setEstampa}
            data={data
              .map((item) => item.estampas.map((estampas) => estampas.estampa))
              .flat()}
            slidesNumber={3}
            description={"Estampas (Parte de fora da tala)"}
          />
        )}
      </div>
    </>
  );
};

export const Customs2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      localStorage.removeItem("hasReloaded");
    }
  }, [navigate]);

  const nextSlide = () => {
    navigate("/3");
  };

  const prevSlide = () => {
    navigate("/1");
  };

  return (
    <>
      <div className="fade-in">
        <TalaCustomPatterned />
        <div className=" absolute bottom-0 p-0" onClick={prevSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronLeft />
            </button>
          </div>
        </div>

        <div className="absolute bottom-0 p-0 right-0" onClick={nextSlide}>
          <div className="m-6 flex items-center justify-between">
            <button className="text-black px-4 rounded no-padding">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
