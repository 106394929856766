import { combineReducers } from "redux";
import priceReducer from "./priceReducer";
import itemReducer from "./itemReducer";

const rootReducer = combineReducers({
  price: priceReducer,
  item: itemReducer,
});

export default rootReducer;
